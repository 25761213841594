import React from 'react'

export default function Footer2() {
  return (
    <div>
        <h6 className="mt-5 p-1 text-center text-secondary ">Copyright © 2022 Flight Try. All Rights Reserved. </h6>
        <h6 className="mt-1  text-center text-secondary ">Designed and code by Hackerstreet</h6>
    </div>
  )
}
